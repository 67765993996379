<template>
  <div class="theme-bg-color text-center" style="padding-top: 5em;padding-bottom:5em;">
    <div class="container-lg">
      <h3 class="mb-4">您有需求？即刻联系我们吧！</h3>
      <button type="button" class="btn btn-primary" @click="$router.push({ name: 'Contact' })">联系我们 <svg-icon name="arrow-right-circle"></svg-icon></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'please-contact-us'
}
</script>

<style scoped>

</style>
