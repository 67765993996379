<template>
  <div class="d-flex flex-column min-vh-100 layout">
    <layout-head></layout-head>
    <div class="layout-body">
      <router-view></router-view>
    </div>
    <layout-foot class="mt-auto"></layout-foot>
  </div>
</template>

<script>
import LayoutHead from './layout-head'
import LayoutFoot from './layout-foot'

export default {
  name: 'layout-index',
  components: {
    LayoutHead,
    LayoutFoot
  },
}
</script>

<style lang="scss" scoped>
.layout {

}
</style>
