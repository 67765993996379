<template>
  <div class="page">
    <custom-breadcrumb></custom-breadcrumb>
    <div class="container-lg">
      <!-- <div class="text-center my-sm-5 button-groups">
        <button type="button" class="btn btn-outline-primary mx-sm-2 mb-3 mb-sm-0" @click="filterLists('*')">全部</button>
        <button type="button" class="btn btn-outline-primary mx-sm-2 mb-3 mb-sm-0" @click="filterLists('.device-1')">数据处理设备
        </button>
        <button type="button" class="btn btn-outline-primary mx-sm-2 mb-3 mb-sm-0" @click="filterLists('.device-2')">数据采集设备
        </button>
        <button type="button" class="btn btn-outline-primary mx-sm-2 mb-3 mb-sm-0" @click="filterLists('.device-3')">辅助设备</button>
      </div> -->
      <div class="grid row" ref="isotope">
        <div class="col-12 col-sm-6 col-md-3 grid-item" v-for="(list, index) in lists" :key="index" :class="list.type">
          <div class="image-box">
            <img class="grid-image" v-preview-image="list.image" :src="list.image" alt="">
          </div>
        </div>
      </div>
    </div>
    <please-contact-us></please-contact-us>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.grid-item {
  width: 100%;
  margin-bottom: $grid-gutter-width / 2;
}

.grid-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.image-box {
  background: $gray-100;
}

@include media-breakpoint-up('sm') {
  .grid-item {
    height: 300px;
    text-align: center;
    line-height: 300px;
    margin-bottom: $grid-gutter-width;
  }
  .grid-image {
    width: 60%;
    height: 60%;
  }
}
</style>

<script>
import Isotope from 'isotope-layout'

export default {
  name: 'products-index',
  data () {
    return {
      lists: Array(3).fill(1).map((v, i) => {
        return {
          type: (['device-1', 'device-2', 'device-3'])[Math.round(Math.random() * 2)],
          image: require('./product-' + i + '.png')
        }
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    init () {
      const elem = this.$refs.isotope
      const options = {
        itemSelector: '.grid-item',
        percentPosition: true,
      }
      this.iso = new Isotope(elem, options)
    },
    filterLists (selector) {
      if (this.iso) {
        this.iso.arrange({
          filter: selector,
        })
      }
    },
  },
}
</script>
