import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

const originPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (rawLocation) {
  return originPush.call(this, rawLocation).catch(() => {
  })
}

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    meta: { title: '网站首页' },
    component: Layout,
    // redirect: '/home',
    children: [
      {
        path: '/',
        meta: { title: '网站首页' },
        name: 'Home',
        component: () => import('@/views/home')
      }
    ]
  },
  {
    path: '/about',
    meta: { title: '关于我们' },
    component: Layout,
    children: [
      {
        path: 'overview',
        meta: { title: '公司概况' },
        name: 'AboutOverview',
        component: () => import('@/views/about/overview')
      },
      {
        path: 'culture',
        meta: { title: '企业文化' },
        name: 'AboutCulture',
        component: () => import('@/views/about/culture')
      },
    ]
  },
  // {
  //   path: '/news',
  //   meta: { title: '新闻资讯' },
  //   component: Layout,
  //   children: [
  //     {
  //       path: '',
  //       meta: { title: '新闻资讯' },
  //       name: 'News',
  //       component: () => import('@/views/news/index')
  //     },
  //   ]
  // },
  {
    path: '/qualification-certificate',
    meta: { title: '资质证书' },
    component: Layout,
    children: [
      {
        path: '',
        meta: { title: '资质证书' },
        name: 'QualificationCertificate',
        component: () => import('@/views/qualification-certificate/index')
      }
    ]
  },
  {
    path: '/services',
    meta: { title: '服务项目' },
    component: Layout,
    children: [
      {
        path: 'one',
        meta: { title: '基础产品' },
        name: 'EnterpriseServices',
        component: () => import('@/views/services/one')
      },
      {
        path: 'two',
        meta: { title: '增值服务' },
        name: 'EnterpriseServices1',
        component: () => import('@/views/services/two')
      },
      {
        path: 'three',
        meta: { title: '生态集成' },
        name: 'EnterpriseServices2',
        component: () => import('@/views/services/three')
      },
      {
        path: 'four',
        meta: { title: '主营业务' },
        name: 'EnterpriseServices3',
        component: () => import('@/views/services/four')
      }
    ]
  },
  {
    path: '/products',
    meta: { title: '产品展示' },
    component: Layout,
    children: [
      {
        path: '',
        meta: { title: '产品展示' },
        name: 'Products',
        component: () => import('@/views/products')
      },
    ]
  },
  {
    path: '/technical-support',
    meta: { title: '技术支持' },
    component: Layout,
    children: [
      {
        path: 'download',
        meta: { title: '技术支持下载' },
        name: 'TechnicalSupportDownload',
        component: () => import('@/views/technical-support/download')
      },
      {
        path: 'details',
        meta: { title: '配置视频详解' },
        name: 'TechnicalSupportDetails',
        component: () => import('@/views/technical-support/details')
      },
      {
        path: 'manual',
        meta: { title: '使用说明文档' }, 
        name: 'TechnicalSupportDetails1',
        component: () => import('@/views/technical-support/manual')
      },
    ]
  },
  {
    path: '/cases',
    meta: { title: '客户案例' },
    component: Layout,
    children: [
      {
        path: '',
        meta: { title: '客户案例' },
        name: 'Cases',
        component: () => import('@/views/cases')
      }
    ]
  },
  {
    path: '/contact',
    meta: { title: '联系我们' },
    component: Layout,
    children: [
      {
        path: '',
        meta: { title: '联系我们' },
        name: 'Contact',
        component: () => import('@/views/contact')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
