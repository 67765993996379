<template>
  <div class="page">
    <custom-breadcrumb></custom-breadcrumb>
    <div class="container-lg">
      <p>我们致力于为您提供卓越的企业服务，以满足您在业务发展过程中的需求。</p>
      <p>我们的专业团队拥有丰富的行业经验和专业知识，能够为您提供从市场调研、战略规划到运营实施的全方位服务。我们以客户满意为最高准则，通过创新、高效、可靠的服务，帮助您实现业务目标，提升竞争力。</p>
      <p>我们的服务范围涵盖了企业发展的各个阶段。无论您是初创企业需要品牌建设，还是成长型企业寻求突破，或是成熟企业寻求创新发展，我们都能为您提供量身定制的解决方案。
      我们承诺，我们将以诚信、专业、高效的服务，为您提供有力的支持，陪伴您的企业不断成长壮大。让我们携手并进，共创辉煌未来！</p>

      <h5 class="mt-5 mb-3">主营业务</h5>
      <p>1）企业能源管理</p>
      <p>企业能源管理系统是为高耗能工业企业设计开发的专业化智能化的综合性能源管理系统。</p>
      <p>企业能源管理系统适用于化工、橡胶、钢铁、有色金属、建材、煤炭、石油、加工制造等高耗能行业，满足企业对于能源管理的信息化、专业化和流程化的需求。</p>

      <p>2）政府能源系统</p>
      <p>以省/地市/区县等各级政府节能管理部门和五大领域重点用能单位为服务对象，提供涵盖智慧能源综合监管、能耗限额对标电算化、节能监察工业园区能源监管等全方位业务体系，从不同层面全力推动区域节能减排综合监管信息化建设进程</p>

      <p>3）建筑能源管理</p>
      <p>为新建绿色建筑和既有建筑的绿色化改造提供规划和咨询、方案设计、建筑施工、运行维护等服务。并且以能耗数据采集和动态管理为基础，将计算机技术、通讯技术、控制技术和现代建筑技术相结合，实现建筑能耗监测、能耗分析、用能考核、智能报警等功能。</p>

      <p>4）城市公共能源管理</p>
      <p>以管网运用数据为中心，整合自动化技术，地理信息技术等，实现用热单位及居民室内温度、管网压力、流量、温度等参数，换热站和热源厂数据的汇集管理，做出可视化处理结果辅助建议。</p>

      <p>5）建筑智能化建设</p>
      <p>以“智能大脑”为中枢，利用云计算、大数据、物联网、人工智能、GIS等先进的信息技术，围绕业务链，为参与主体提供全场景、全流程的数字化、智能化解决方案。适用场景：工厂、医院、学校、社区</p>

      <p>6）智慧水务管理平台搭建</p>
      <p class="mb-5">运营管理平台、厂站一体化管理平台、 区域一体化供水管理平台、水环境智慧化管理平台、水库安全综合监管管理平台、水务碳能综合管理平台</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'services-index'
}
</script>

<style scoped>

</style>
