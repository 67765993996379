<template>
  <div>
    <div class="custom-breadcrumb mb-3 mb-sm-5">
      <h1 class="text-primary text-center breadcrumb-title">{{ $route.meta.title }}</h1>
    </div>
<!--    <div class="container-lg">-->
<!--      <nav aria-label="breadcrumb" class="my-3">-->
<!--        <ol class="breadcrumb bg-white mb-0">-->
<!--          <li class="breadcrumb-item"><a href="#">首页</a></li>-->
<!--          <li class="breadcrumb-item active" aria-current="page">{{ $route.meta.title }}</li>-->
<!--        </ol>-->
<!--      </nav>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'custom-breadcrumb',
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.custom-breadcrumb {
  width: 100%;
  height: 250px;
  background: $gray-100 url("./wind.jpg") no-repeat;
  background-position: 0 48%;
  overflow: hidden;
}

.breadcrumb-title {
  line-height: 330px;
}

@include media-breakpoint-up('md') {
  .custom-breadcrumb {
    height: 350px;
  }
  .breadcrumb-title {
    line-height: 420px;
  }
}
</style>
