<template>
  <div class="bg-gray layout-foot pt-5">
    <div class="foot-area py-3">
      <div class="container-lg">
        <div class="row">
          <div class="col-sm-6 col-lg-5">
            <h3 class="mt-4 foot-title">关于我们</h3>
            <p class="foot-text">
              公司依托于体系化的服务流程和自主研发的业务平台，通过对能源管理系统的电子设备研发的整合，秉承“让通信服务更简单，更安全”的企业文化理念，致力于成为最值得客户信赖的物联网通信服务企业。
            </p>
            <!-- <div class="social-share"></div> -->
          </div>
          <div class="col-sm-6 col-lg-2">
            <h3 class="mt-4 foot-title">新闻资讯</h3>
            <ul class="list-unstyled foot-links">
              <li><router-link class="foot-link" to="/news/promotion">优化推广</router-link></li>
              <li><router-link class="foot-link" to="/news/trends">公司动态</router-link></li>
              <li><router-link class="foot-link" to="/news/experience">建设经验</router-link></li>
            </ul>
          </div>
          <div class="col-sm-6 col-lg-2">
            <h3 class="mt-4 foot-title">产品展示</h3>
            <ul class="list-unstyled foot-links">
              <li><router-link class="foot-link" to="/products">能耗监测端设备</router-link></li>
              <li><router-link class="foot-link" to="/products">数据采集设备</router-link></li>
            </ul>
          </div>
          <div class="col-sm-6 col-lg-3">
            <h3 class="mt-4 foot-title">联系我们</h3>
            <p class="foot-link">
              <svg-icon name="email"></svg-icon>
              jxtianfei@163.com
            </p>
            <p class="foot-link">
              <svg-icon name="phone"></svg-icon>
              173-7007-5191
            </p>
            <p class="foot-link">
              <svg-icon name="location"></svg-icon>
              南昌市东湖区豫章街道豫章路豫章1号文化科技园
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-5 parts">
      <!-- <div class="container-lg text-center">
        <h3 class="foot-title">合作伙伴</h3>
        <div class="row align-items-center justify-content-center">
          <img src="../../assets/image/yilianlogo.png" alt="" class="parts-image">
          <img src="../../assets/image/zgxlogo.png" alt="" class="parts-image">
        </div>
      </div> -->
    </div>
    <div class="text-center copyright">
      Copyright &copy; 2023
    </div>
  </div>
</template>

<script>
import 'social-share.js/src/css/share.scss'
import 'social-share.js/dist/js/social-share.min'

export default {
  name: 'layout-foot',
  mounted () {
    if (window.socialShare) {
      window.socialShare('.social-share', {
        // sites: ['qzone', 'qq', 'weibo', 'wechat', 'douban'], // 启用的站点
        disabled: ['google', 'facebook', 'twitter', 'diandian', 'linkedin'], // 禁用的站点
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.layout-foot {
  color: $gray-600;
}
.foot-title {
  font-size: $font-size-lg;
  color: $gray-500;
}
.foot-link {
  color: inherit;
  margin: 0;
  line-height: 2.5em;
  font-size: $font-size-sm;
}
.foot-text {
  font-size: $font-size-sm;
}
.copyright {
  height: 40px;
  color: $gray-500;
  background: $gray-100;
  line-height: 40px;
}
//.social-share ::v-deep .social-share-icon {
//  color: $gray-500;
//  border-color: $gray-500;
//  &:hover {
//    background: transparent;
//    color: $gray-100;
//    border-color: $gray-100;
//  }
//}
</style>
