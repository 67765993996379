<template>
  <div class="page">
    <custom-breadcrumb></custom-breadcrumb>
    <div class="container-lg mb-5">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4" v-for="(list, index) in lists" :key="index">
          <div class="card-item rounded-lg">
            <img class="card-image" :src="list.image" :alt="list.image">
            <div class="card-content p-3 px-4">
              <h3 class="mt-4">{{list.title}}</h3>
              <p class="text-muted">{{list.content}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <please-contact-us></please-contact-us>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.card-item {
  box-shadow: 0 0 1px rgba(0,0,0,0.5);
  margin-bottom: $grid-gutter-width;
  transition: all .3s ease-in-out;
  overflow: hidden;
  @include hover () {
    transform: translateY(-7px);
    box-shadow: $box-shadow;
  }
}
.card-image {
  width: 100%;
  height: 240px;
  object-fit: cover;
}
.card-content {
  height: 240px;
}
</style>

<script>
export default {
  name: 'about-overview',
  data () {
    return {
      lists: [
        { title: '江西天飞科技有限责任公司', image: require('./about-0.jpg'), content: '天飞科技创立于2016年，总部位于江西省南昌市，天飞科技深耕能源管理服务行业多年，是一家具有丰富行业经验的服务提供商。公司依托于体系化的服务流程和自主研发的业务平台，通过对能源管理系统的电子设备研发的整合，秉承“让通信服务更简单，更安全”的企业文化理念，致力于成为最值得客户信赖的物联网通信服务企业。' },
        { title: '“海阔凭鱼跃，天高任鸟飞”', image: require('./about-1.jpg'), content: '是天飞的发展理念，市场是现实而又残酷的，我们都要懂得适者生存。我们相信只要掌握了足够的本领，天下之大，肯定会有让我们施展才华的地方。' },
        { title: '“以人为本、诚信立业”', image: require('./about-2.jpg'), content: '是公司自成立以来，始终坚持的经营原则，荟萃行业精英，将先进的信息技术、管理方法及企业经验与国内企业的具体实际相结合，为企业提供全方位的解决方案，帮助企业提高管理水平和生产能力，使企业在激烈的市场竞争中始终保持竞争力，实现企业快速、稳定地发展。' },
        { title: '最好的产品，最好的服务，最好的声誉', image: require('./about-3.jpg'), content: '天飞科技在江西、广西、安徽、山东、内蒙古等地区的高能耗企业领域取得辉煌成绩，完成众多大型能耗监测的配套服务，也愿意成为您最可信赖的长期合作伙伴。' },
      ]
    }
  },
}
</script>
