<template>
  <div class="page">
    <banner-swiper></banner-swiper>
    <focus-on-enterprise-energy-monitor></focus-on-enterprise-energy-monitor>
    <news-trends></news-trends>
    <please-contact-us></please-contact-us>
  </div>
</template>

<script>
import BannerSwiper from '@/views/home/banner-swiper'
import FocusOnEnterpriseEnergyMonitor from '@/views/home/focus-on-enterprise-energy-monitor'
import NewsTrends from '@/views/home/news-trends'

export default {
  name: 'home-index',
  components: {
    BannerSwiper,
    FocusOnEnterpriseEnergyMonitor,
    NewsTrends,
  },
  data () {
    return {}
  },
}
</script>
