import { render, staticRenderFns } from "./slide-item.vue?vue&type=template&id=eb1c9fc4&scoped=true"
import script from "./slide-item.vue?vue&type=script&lang=js"
export * from "./slide-item.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb1c9fc4",
  null
  
)

export default component.exports