<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted () {
    window.addEventListener('scroll', this.handlerScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handlerScroll)
  },
  methods: {
    handlerScroll (evt) {
      const scrollTop = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop
      const header = document.querySelector('.layout-head')
      const isMobile = /Mobile/.test(window.navigator.userAgent)
      const height = window.innerHeight
      if (isMobile) return false
      try {
        if (scrollTop > height / 3) {
          header.style.cssText = 'background: var(--bg-color) !important;box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);'
        } else {
          header.style.cssText = 'background: transparent !important;box-shadow: none;'
        }
      } catch (e) {
        console.warn(e);
      }
    },
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
