<template>
  <div class="page">
    <custom-breadcrumb></custom-breadcrumb>
    <div class="container-lg">
      <div class="list-group">
        <a v-for="list in lists" :key="list.title" :download="list.title" :href="'/zip/' + list.title"  class="list-group-item border-0 list-group-item-action" >
          <div class="d-flex align-items-center">
            <h6 class="text-truncate mb-0">{{list.title}}</h6>
            <span class="ml-auto text-gray">{{list.date}}</span>
          </div>
        </a>
      </div>
    </div>
    <please-contact-us></please-contact-us>
  </div>
</template>

<script>
export default {
  name: 'technical-support-manual',
  data () {
    return {
      lists: [
        { title: '天飞DTU说明书.pdf', date: '2024-08-01', },
      ]
    }
  },
}
</script>
