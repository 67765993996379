<template>
  <div class="page">
    <custom-breadcrumb></custom-breadcrumb>
    <div class="container-lg mb-5">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4" v-for="(list, index) in lists" :key="index">
          <div class="item rounded position-relative">
            <img class="item-image" :src="list.image" alt="">
            <div class="item-mask">
              <h4 class="mb-4 item-title">{{ list.title }}</h4>
              <button type="button" class="btn btn-outline-light">
                浏览
                <svg-icon name="arrow-right"></svg-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <please-contact-us></please-contact-us>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.item-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.item-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-title {
  transform: translateY(-7px);
  transition: transform .3s ease;
}

.item {
  overflow: hidden;
  margin-bottom: $grid-gutter-width;

  &:hover {
    .item-mask {
      visibility: visible;
      opacity: 1;
    }

    .item-title {
      transform: translateY(0);
    }
  }
}

@include media-breakpoint-up('md') {
  .item-mask {
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
  }
  .item-image {
    height: 280px;
  }
}
</style>

<script>
export default {
  name: 'case-index',
  data () {
    return {
      lists: [
        {
          title: '安徽启航环保科技有限公司',
          link: '###',
          image: require('./case1.jpg'),
        },
        {
          title: '河南鸿泰节能技术有限公司',
          link: '###',
          image: require('./case2.jpg'),
        },
        {
          title: '安徽启航环保科技有限公司',
          link: '###',
          image: require('./case3.jpg'),
        },
        {
          title: '河南鸿泰节能技术有限公司',
          link: '###',
          image: require('./case4.jpg'),
        },
      ]
    }
  },
}
</script>
