<template>
  <div class="theme-bg-color text-center px-sm-3 py-5">
    <div class="container-lg">
      <h3 class="mt-md-5 mb-md-4 text-primary">专注企业用能监测</h3>
      <h5 class="text-muted">我们做什么？</h5>
      <div class="mt-3 mt-md-5 row">
        <div class="col-12 col-sm-6 col-md-3" v-for="(item, index) in items" :key="index">
          <div class="d-inline-block item-image">
            <svg-icon :name="item.icon"></svg-icon>
          </div>
          <h5 class="mt-3 mt-sm-5">{{item.h}}</h5>
          <p class="mb-5 text-gray">{{item.p}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'focus-on-enterprise-energy-monitor',
  data () {
    return {
      items: [
        { icon: 'desktop', h: '能耗在线监测', p: '企业高端资源监控系统数据采集、数据上传' },
        { icon: 'code', h: '高效设备选材', p: '"拒绝平庸 只用精品"捕捉需求、极致规划' },
        { icon: 'users', h: '专业团队服务', p: '"专业团队 超前思维"全方位方案设计' },
        { icon: 'recycle', h: '专业服务器运维', p: '"快速响应，安心服务"远程监控、快速响应' },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.item-image {
  border: 1px solid $theme-color;
  height: 100px;
  width: 100px;
  line-height: 100px;
  border-radius: 50%;
  font-size: 45px;
  color: $theme-color;
}
</style>
