import Vue from 'vue'
import '@/assets/style/main.scss'
import App from './App.vue'
import router from './router'
import Icons from './icons'
import CustomBreadcrumb from '@/components/custom-breadcrumb'
import PleaseContactUs from '@/components/please-contact-us'

Vue.use(Icons)
Vue.config.productionTip = false
Vue.component('custom-breadcrumb', CustomBreadcrumb)
Vue.component('please-contact-us', PleaseContactUs)
Vue.directive('preview-image', {
  bind (el, binding) {
    el.__preview_image_handler__ = (evt) => {
      const imgSrc = binding.value
      console.log(imgSrc)
      let imageWrapper = document.getElementById('preview_image_wrapper')
      if (imageWrapper) {
        imageWrapper.querySelector('img').setAttribute('src', imgSrc)
        imageWrapper.style.display = 'flex'
      } else {
        imageWrapper = document.createElement('div')
        imageWrapper.id = 'preview_image_wrapper'
        imageWrapper.innerHTML = `<img class="mw-100 mh-100" src="${imgSrc}" alt="${imgSrc}">`
        imageWrapper.addEventListener('click', () => {
          imageWrapper.style.display = 'none'
        })
        document.body.appendChild(imageWrapper)
      }
    }
    el.addEventListener('click', el.__preview_image_handler__, false)
  },
  unbind (el) {
    el.removeEventListener('click', el.__preview_image_handler__, false)
  },
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
