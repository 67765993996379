<template>
  <div class="page">
    <custom-breadcrumb></custom-breadcrumb>
    <div class="container-lg mb-5">
      <div class="row flex-sm-nowrap" ref="wrapper">
        <div class="col-12 col-sm-6 col-md-4 col-lg" v-for="(list, index) in lists" :key="index">
          <div class="rounded card-item" :class="{'is-hover': index === 2}">
            <img class="card-image" :src="list.image" :alt="list.image">
            <div class="card-content px-3">
              <h1 class="mt-3 card-title">{{list.title}}</h1>
              <p class="card-text">{{list.content}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <please-contact-us></please-contact-us>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.card-item {
  box-shadow: 0 0 1px rgba(0,0,0,0.5);
  margin-bottom: $grid-gutter-width;
  overflow: hidden;
  transition: all .3s ease-in-out;
  @include hover () {
    box-shadow: $box-shadow;
    //transform: translateY(-7px);
    position: relative;

    .card-content {
    }
    .card-title {
    }
    .card-text {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 500px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      background: rgba(0,0,0,0.4);
      color: white;
      font-size: 2rem;
      text-align: center;
      padding-left: 4em;
      padding-right: 4em;
    }
    .card-image {
      width: 600px;
    }
  }
}
.card-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
@include media-breakpoint-up('lg') {
  .card-item {
    margin-bottom: 0;
  }
  .card-image {
    height: 500px;
  }
  .card-text {
    display: none;
  }
}
</style>

<script>
export default {
  name: 'about-culture',
  data () {
    return {
      lists: [
        { title: '2017', content: '我们初步创立，满怀初心，秉着诚信经营的理念，踏上征程', image: require('./about2-1.jpg'), },
        { title: '2018', content: '我们开拓市场，四处奔波，终于干成一点业绩，开始展望未来', image: require('./about2-2.jpg'), },
        { title: '2019', content: '我们成为江西首家青岛高校信息产业股份有限公司的代理经销商，销售高信公司的产品，进行安装维保', image: require('./about2-3.jpg'), },
        { title: '2020', content: '我们初具规模，业务量也逐渐增大，做到企业规范化', image: require('./city.jpg'), },
        { title: '2021', content: '我们经手的项目逐步增多，遍布全国各地，高质量的完成项目验收', image: require('./city2.jpg'), },
        { title: '2022', content: '我们在江西、广西、内蒙古、山东、安徽等地区实施了近600家企业，年销售额达到￥15,000,000+，我们做到专业化', image: require('./meet1.jpg'), },
        { title: '2023', content: '自公司创立以来，我们不断成长，成为青岛高校信息产业股份有限公司的经销商，代理商，年销售额达到￥15,000,000+', image: require('./sunset.jpg'), },
      ],
    }
  },
}
</script>
