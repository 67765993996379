<template>
  <div class="swiper-slide banner-slide">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'slide-item'
}
</script>

<style scoped>

</style>
