<template>
  <div class="page">
    <custom-breadcrumb></custom-breadcrumb>
    <div class="container-lg">
      <p>我们致力于为您提供卓越的企业服务，以满足您在业务发展过程中的需求。</p>
      <p>我们的专业团队拥有丰富的行业经验和专业知识，能够为您提供从市场调研、战略规划到运营实施的全方位服务。我们以客户满意为最高准则，通过创新、高效、可靠的服务，帮助您实现业务目标，提升竞争力。</p>
      <p>我们的服务范围涵盖了企业发展的各个阶段。无论您是初创企业需要品牌建设，还是成长型企业寻求突破，或是成熟企业寻求创新发展，我们都能为您提供量身定制的解决方案。
      我们承诺，我们将以诚信、专业、高效的服务，为您提供有力的支持，陪伴您的企业不断成长壮大。让我们携手并进，共创辉煌未来！</p>
      <h5 class="mt-5 mb-3">基础产品</h5>
      <p>1）重点能用单位能耗在线监测平台、大气污染治理云平台</p>
      <p>2）企业能耗在线监测端设备、能源数据实时采集物联网网关（有线、无线、非接入式）</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'services-index'
}
</script>

<style scoped>

</style>
