<template>
  <div class="bg-transparent fixed-top py-3 py-md-4 layout-head">
    <div class="container-lg">
      <div class="d-flex align-items-center position-relative">
        <a class="logo" href="/">
          <img class="img-fluid logo-image" src="../../assets/image/tf.png" alt="logo">
        </a>

        <div class="ml-auto mr-3 navs-box" :class="{'is-collapsed': collapsed}">
          <ul class="d-md-flex mb-0 list-unstyled navs">
            <li class="nav" v-for="nav in navs" :key="nav.path">
              <template v-if="nav.children && nav.children.length > 0">
                <div class="nav-link">
                  <span class="text-nowrap">{{ nav.meta.title }}</span>
                  <span class="ml-auto arrow-down">
                    <svg-icon name="down"></svg-icon>
                  </span>
                </div>
                <ul class="list-unstyled rounded-sm navs-children">
                  <li class="nav-child" v-for="item in nav.children" :key="item.path">
                    <router-link class="text-nowrap nav-link" :to="item.path" exacts @click.native="collapsed = false">
                      {{ item.meta.title }}
                    </router-link>
                  </li>
                </ul>
              </template>
              <router-link v-else class="text-nowrap nav-link" :to="nav.path" exact @click.native="collapsed = false">{{ nav.meta.title }}</router-link>
            </li>
          </ul>
        </div>

        <button class="theme-button" @click="toggleThemeButton">
          <svg-icon :name="themeButtonIcon"></svg-icon>
        </button>
        <button
          class="d-md-none rounded-sm navbar-button"
          :class="{'is-collapsed': collapsed}"
          @click="toggleNavbarButton"
        >
          <svg-icon :name="navbarIcon"></svg-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { routes } from '@/router'

function getNavs (routes, prefixPath = '/') {
  return routes.reduce((acc, v) => {
    const route = {}
    route.meta = v.meta || { title: '' }
    route.path = /^\//.test(v.path) ? v.path : prefixPath + '/' + v.path
    if (v.redirect) {
      route.path = v.redirect
    }

    if (v.children) {
      if (v.children.length === 1) {
        const child = v.children[0]
        route.path = /^\//.test(child.path) ? child.path : route.path + '/' + child.path
        route.path = route.path.replace('//', '/');
      } else if (v.children.length > 1) {
        route.children = getNavs(v.children, route.path)
      }
    }
    acc.push(route)
    return acc
  }, [])
}

export default {
  name: 'layout-head',
  data () {
    return {
      navs: getNavs(routes),
      collapsed: false,
      theme: 'light'
    }
  },
  computed: {
    navbarIcon () {
      return this.collapsed ? 'close' : 'bars'
    },
    themeButtonIcon () {
      return this.theme === 'light' ? 'night-mode' : 'daytime-mode'
    },
  },
  methods: {
    toggleNavbarButton () {
      this.collapsed = !this.collapsed
    },
    toggleThemeButton () {
      this.theme = this.theme === 'light' ? 'dark' : 'light'
      if (this.theme === 'dark') {
        // document.documentElement.setAttribute('[data-theme]', 'dark')
        document.documentElement.dataset.theme = 'dark'
      } else {
        // document.documentElement.removeAttribute('[data-theme]')
        delete document.documentElement.dataset.theme
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.layout-head {
  @include media-breakpoint-down('md') {
    background: var(--bg-color) !important;
    box-shadow: $box-shadow;
  }
}
.logo {
  flex: 0 0 auto;
}

.logo-image {
  width: 120px;
}

.navs-box {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
  display: none;
  @include media-breakpoint-up('md') {
    display: block;
    position: relative;
    .nav {
      position: relative;
    }
  }
  @include media-breakpoint-up('lg') {
    position: relative;
  }
}

.nav-link {
  color: inherit;
  white-space: nowrap;

  .text-nowrap {
    margin-right: 3px;
  }
}

.arrow-down {
  color: var(--text-secondary-color);
  font-size: $font-size-sm;
}

.navs-children {
  @include media-breakpoint-up('md') {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 1rem;
    z-index: 1;
    font-size: $font-size-sm;
    transform: translateY(-5px);
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-in-out;
    background: var(--bg-color);

    .nav-link {
      font-size: inherit;
      color: var(--text-secondary-color);

      &:hover {
        color: $theme-color;
      }
    }
  }
}

.nav:hover .navs-children {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.navbar-button,
.theme-button {
  border: none;
  font-size: $font-size-lg;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 1;
  color: var(--text-primary-color);
}

.navbar-button {
  background: var(--bg-color);
  &:active {
    border: 1px solid currentColor;
  }
}

.theme-button {
  background: transparent;
  @include media-breakpoint-down('md') {
    margin-left: auto;
  }
}

.is-collapsed {
  @include media-breakpoint-down('md') {
    &.navs-box {
      display: block;
      left: 0;
      margin-top: 1rem;
      background: var(--bg-color);
      box-shadow: $box-shadow-sm;
    }

    .navs,
    .nav {
      width: 100%;
    }

    .nav {
      display: block;
      & > .nav-link {
        background: $gray-100;
      }
    }
    .nav-link {
      display: flex;
    }
    .navs-children {
      padding-left: 1rem;
    }
  }
}
</style>
