<template>
  <div>
    <div class="swiper" id="swiper">
      <div class="swiper-wrapper">
        <slide-item class="text-center">
          <img class="slide-image" src="./banner7.jpg" alt="banner7">
        </slide-item>
        <slide-item class="text-center">
          <img class="slide-image" src="./banner3.jpg" alt="banner3">
        </slide-item>
        <slide-item class="text-center">
          <img class="slide-image" src="./banner4.jpg" alt="banner5">
        </slide-item>
      </div>
    </div>
    <div class="position-absolute text-center banner-content">
      <transition name="fade-in">
        <h2 v-show="showTitle" class="slide-title">五年专注，天飞科技</h2>
      </transition>
      <transition name="fade-in">
        <h4 v-show="showSubTitle" class="slide-subtitle">深耕能源管理服务行业多年，是一家具有丰富行业经验的服务提供商。</h4>
      </transition>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css'
import SlideItem from '@/views/home/banner-swiper/slide-item'

export default {
  name: 'banner-swiper',
  components: {
    SlideItem
  },
  props: {},
  data () {
    return {
      swiper: null,
      showTitle: false,
      showSubTitle: false,
    }
  },
  mounted () {
    this.initSwiper()
    setTimeout(() => {
      this.showTitle = true
    }, 500)
    setTimeout(() => {
      this.showSubTitle = true
    }, 1000)
  },
  methods: {
    initSwiper () {
      this.swiper = new Swiper('#swiper', {})
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.swiper {
  width: 100%;
  height: 300px;
  @include media-breakpoint-up('sm') {
    height: 100vh;
  }
}

.slide-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-title {
  display: inline-block;
  padding: .8rem 1rem;
  border-radius: .3rem;
  background: $theme-color;
  color: white;
  //margin-top: 16%;
  letter-spacing: 2px;
  white-space: nowrap;
}

.slide-subtitle {
  margin-top: 2rem;
  color: $theme-color;
  letter-spacing: 2px;
}
.fade-in-enter {
  transform: translateY(-100%);
  opacity: 0;
}
.fade-in-enter-active {
  transition: transform 1.2s, opacity 1.2s;
}
.banner-content {
  top: 13%;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  @include media-breakpoint-up('md') {
    top: 40%;
  }
}
@include media-breakpoint-down('md') {
  .slide-title {
    font-size: $font-size-lg;
  }
  .slide-subtitle {
    width: 300px;
    font-size: $font-size-base;
    margin-top: .8rem;
    line-height: 1.6;
    padding: 1rem;
  }
}
</style>
