<template>
  <div class="page">
    <custom-breadcrumb></custom-breadcrumb>
    <div class="container-lg mb-5">
      <div class="row">
        <div class="col-12 col-md-6" v-for="(list, index) in lists" :key="index">
          <div class="card-item">
            <video class="card-video" controls>
              <source :src="list.src" type="video/mp4">
            </video>
            <h4 class="text-center mt-3">{{list.title}}</h4>
          </div>
        </div>
      </div>
    </div>
    <please-contact-us></please-contact-us>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.card-video {
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>

<script>
export default {
  name: 'technical-support-details',
  data () {
    return {
      lists: [
        { title: 'DTU配置视频', src: require('./DTU配置视频.mp4') },
        { title: '配置两个FRP程序同时启用', src: require('./配置两个FRP程序同时启用.mp4') },
      ]
    }
  },
}
</script>
