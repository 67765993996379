<template>
  <div class="page">
    <custom-breadcrumb></custom-breadcrumb>
    <div class="container-lg mb-5">
      <div class="row">
        <div class="col-12 col-sm-3 col-md-3" v-for="(list, index) in lists" :key="index">
          <div class="d-flex align-items-center justify-content-center image-box">
            <img class="img-fluid" v-preview-image="list" :src="list" alt="list">
          </div>
        </div>
      </div>
    </div>
    <please-contact-us></please-contact-us>
  </div>
</template>

<script>
export default {
  name: 'qualification-certificate',
  data () {
    return {
      lists: [
        require('./1.jpg'),
        require('./2.jpg'),
        require('./3.jpg'),
        require('./4.jpg'),
        require('./5.jpg'),
        require('./6.jpg'),
        require('./7.jpg'),
        require('./8.jpg'),
        require('./智慧供水管控系统证书.jpg'),
        require('./重点用能端设备系统证书.jpg'),
        require('./9.jpg'),
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.image-box {
  background: $gray-100;
  padding: 3em;
  margin-bottom: $grid-gutter-width / 2;
}
@include media-breakpoint-up('sm') {
  .image-box {
    padding: 1em;
  }
}
@include media-breakpoint-up('lg') {
  .image-box {
    padding: 3em;
  }
}
</style>
