<template>
  <div class="page">
    <custom-breadcrumb></custom-breadcrumb>
    <div class="container-lg">
      <div class="list-group">
        <a v-for="list in lists" :key="list.title" :href="'/zip/' + list.title"  class="list-group-item border-0 list-group-item-action">
          <div class="d-flex align-items-center">
            <h6 class="text-truncate mb-0">{{list.title}}</h6>
            <span class="ml-auto text-gray">{{list.date}}</span>
          </div>
        </a>
      </div>
    </div>
    <please-contact-us></please-contact-us>
  </div>
</template>

<script>
export default {
  name: 'technical-support-download',
  data () {
    return {
      lists: [
        { title: 'DTU配置工具.7z', date: '2023-06-01', },
        { title: 'SSH工具.7z', date: '2023-05-23', },
        { title: 'Mysql工具.7z', date: '2023-05-11', },
        { title: 'Modbus测试工具.zip', date: '2023-04-02', },
        { title: 'Modbus设备寻址工具485.exe', date: '2023-03-21', },
        { title: '串口工具.zip', date: '2023-03-11', },
        // { title: '卓兰信号分配器配置工具.7z', date: '2023-02-18', },
        // { title: 'OPC Client连接测试工具.exe', date: '2023-01-01', },
      ]
    }
  },
}
</script>
