<template>
  <div class="bg-gray text-center py-5 news-trends">
    <div class="container-lg">
      <h3 class="mt-md-5 mb-md-4 text-primary">新闻动态</h3>
      <h5 class="text-muted">即时发布我们最新的产品及服务动态</h5>
      <div class="mt-3 mt-md-5 row">
        <div class="col-12 col-sm-6 col-md-4" v-for="(item, index) in lists" :key="index">
          <div class="card-item">
            <img class="rounded-lg card-image" :src="item.cover" :alt="item.cover">
            <h4 class="my-4">{{item.label}}</h4>
            <ul class="list-unstyled">
              <li class="d-flex align-items-center px-4 py-2" v-for="(list, idx) in item.lists" :key="idx">
                <a href="javascript:void(0);" class="text-truncate list-link">{{list.title}}</a>
                <span class="text-gray flex-shrink-0 ml-auto">{{list.date}}</span>
              </li>
            </ul>
            <div class="px-4 pb-3">
              <button type="button" class="btn btn-primary">查看更多</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'news-trends',
  data () {
    return {
      lists: [
        {
          label: '优化推广',
          cover: require('./news1.jpg'),
          lists: [
            {
              id: 1,
              title: '影响数据上传的几大要点',
              date: '2023-06-01',
            },
            {
              id: 2,
              title: '企业用高信端设备进行能源监测的优势',
              date: '2023-06-02',
            }
          ],
        },
        {
          label: '建设经验',
          cover: require('./news2.jpg'),
          lists: [
            {
              id: 3,
              title: '影响数据采集上传的几大要点',
              date: '2023-06-03'
            },
            {
              id: 4,
              title: '为什么企业要建设能耗监测系统？',
              date: '2023-06-03'
            },
          ]
        },
        {
          label: '公司动态',
          cover: require('./news3.jpg'),
          lists: [
            {
              id: 5,
              title: '影响数据采集上传的几大要点',
              date: '2023-06-03'
            },
            {
              id: 6,
              title: '为什么企业要建设能耗监测系统？',
              date: '2023-06-03'
            },
          ]
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.card-item {
  box-shadow: 0 0 1px rgba(0,0,0,0.5);
  transition: all .3s;
  @include hover-focus {
    box-shadow: $box-shadow;
    transform: translateY(-7px);
  }
}
.card-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
}
@include media-breakpoint-up('xl') {
  .card-image {
    height: 280px;
  }
}
</style>
